import React from 'react'
import { graphql } from 'gatsby'
import loadable from '@loadable/component';
import Seo from "../components/seo"
import Layout from '../components/layout'

import { PageLinks } from "../common/site/page-static-links";

import ReviewsImg from "../images/reviews_img.png";


const Banner = loadable(() => import("../components/Banner/Banner"));
const InnerIntro = loadable(() => import("../components/InnerIntro/InnerIntro"));
const PropertyDetailsMap = loadable(() => import("../components/PropertyDetailsMap/PropertyDetailsMap"));
const OfficeForm = loadable(() => import("../components/OfficeForm/OfficeForm"));
const ValuationModule = loadable(() => import("../components/ValuationModule/ValuationModule"));
const ReviewsModule = loadable(() => import("../components/ReviewsModule/ReviewsModule"));

const OfficeDetail = ({ data }, props) => {

    const OfficesData = data?.strapiOffice

    const SiteConfig = data?.strapiSiteConfig?.global_footer_module

    const bannerCTAS = {
        cta_1_title: "Meet the team",
        cta_1_link: {
            link_type: "internal",
            slug: PageLinks.team,
            title: "Meet the team",
        },
        cta_2_title: "General Enquiry",
        cta_2_link: {
            link_type: "internal",
            slug: PageLinks.enquiry,
            title: "General Enquiry",
        }
    }

    return (
        <Layout>
            <Banner
                title={OfficesData?.title}
                tag="landing-banner"
                bannerClass="inner-banner"
                id={OfficesData.strapi_id}
                imagetransforms={OfficesData.imagetransforms}
                {...OfficesData}
                {...bannerCTAS}
                show_review
            />

            <InnerIntro
                title={OfficesData?.about_title}
                heading={OfficesData?.about_heading}
                short_description={{ data: { short_description: OfficesData?.about?.data.about } }}
            />

            <PropertyDetailsMap
                lat={OfficesData?.latitude}
                lng={OfficesData?.longitude}
            />

            <OfficeForm {...OfficesData} />

            <ValuationModule
                {...SiteConfig}
                id={OfficesData.strapi_id}
                imagetransforms={OfficesData.imagetransforms}
            />

            <ReviewsModule
                reviewsImg={ReviewsImg}
            />
        </Layout>
    )
}


export const Head = ({ data }) => {
    const OfficesData = data?.strapiOffice
    const metaDescription = `Our Estate agents in ${OfficesData.title} offer the best property advice in Selling, Buying, Letting and Renting. Contact us to get assistance.`

    var ldJson = {
        "@context": "https://schema.org",
        "@type": "RealEstateAgent",
        "url": process.env.GATSBY_SITE_URL + `/` + PageLinks.contact,
        "name": process.env.GATSBY_SITE_NAME + ` in ` + OfficesData?.title,
        "alternateName": process.env.GATSBY_SITE_NAME,
        "logo": process.env.GATSBY_SITE_URL + `/images/logo.png`,
        "description": OfficesData?.title,
        "address": {
            "@type": "PostalAddress",
            "streetAddress": OfficesData?.address,
            "addressLocality": OfficesData?.address,
            "addressRegion": OfficesData?.address,
            "addressCountry": OfficesData?.address
        },
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": OfficesData?.latitude,
            "longitude": OfficesData?.longitude
        },
        "email": OfficesData?.email,
        "telephone": OfficesData?.phone,
        "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "Sales"
        }
    };

    return (
        <Seo title={OfficesData.title} description={metaDescription}>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
            />
        </Seo>
    )
}

export default OfficeDetail

export const query = graphql`
query ($page_id: Int) {
    strapiOffice(strapi_id: {eq: $page_id}) {
        email
        strapi_id
        phone
        sales_phone
        sales_email
        lettings_phone
        lettings_email
        title
        address
        imagetransforms {
          image_Transforms
        }
        image {
          alternativeText
          url
        }
        banner_content {
            data {
              banner_content
            }
        }
        about_title
        about_heading
        about {
            data {
                about
            }
        }
        latitude
        longitude
        opening_hours {
            data {
                opening_hours
            }
        }
        team_intro {
            data {
                team_intro
            }
        }
        select_team {
            slug
            strapi_id
            image {
                alternativeText
                url
            }
            imagetransforms {
                image_Transforms
            }
            designation
            email
            name
            phone
        }
    }
    strapiSiteConfig {
        global_footer_module {
            strapi_id
            description {
                data {
                    description
                }
            }
            layout
            image {
                url
                alternativeText
            }
            cta_link {
                ...MenuFragment
            }
            cta_title
        }
    }
}`